/*
  Side nav stuff
*/
.sidenav---sidenav---_2tBP {
    background-color: #2647A0 !important;/*#426fd9 !important;*/
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    overflow-y: auto;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL::-webkit-scrollbar {
    width: 8px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL::-webkit-scrollbar-thumb {
    background-color: #ccc;
    outline: 1px solid slategrey;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
    opacity: 1 !important;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > *
{
    color: #ffffff !important;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:last-of-type > .sidenav---sidenav-subnav---1EN61 {
    top: auto;
    bottom: 0;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:nth-last-of-type(2) > .sidenav---sidenav-subnav---1EN61 {
    top: auto;
    bottom: 0;
}

.sidenav-icon {
    height: 20px;
    position: relative;
    width: 24px;
    margin: auto;
    top: 25%;
    left: 2px;
    transform: scale(1.2);
}

.icon--general {
    background-position: -5px 3px;
}

.sidenav-parent{
    font-weight: bolder;
    font-size: 14px;
}

.sidenav-small{
    height: 20px;
    width: 20px;
    display: inline-grid;
    transform: scale(1);
    top: 5px;
}

/*
End sidenav
*/

.p-panel .p-panel-header {

    color: white;
}

.datatable-crud-demo .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.datatable-crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}

.datatable-crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}


body .p-inputtext.p-success {
    border: 1px solid #34A835;
}

.divider {
    padding-left: 5em;
    padding-right: 30em; 
    padding: 0.5em; 
    color: #5F9E9F;
}

.container {
    max-width: 350px;
    margin: 50px 0px;
  }

.container1 {
    width: 100%;
}

.container2 {
    max-width: 350px;
    margin: 50px 0px;
    display: flex;
    justify-content: center;
}

.warning {
    margin-top: 50px;
    width: 50px;
}

.link {
    font-size: 0.7rem;
}

.login-button, .export-button, .logout-button {
    margin: 60px 5px 0px 5px!important;
    background-color: #5f9e9f!important;
    border: 1px solid #5f9e9f!important;
    border-radius: 5px!important;
    width: 110px!important;
    height: 30px!important;
    text-align: center!important;
    padding: 0!important;
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 80px!important;
  }

  .logout-button{
      margin-top: 0px!important;
      margin-bottom: 0px!important;
  }
 
  .logout-button-box {
      width: 100%;
      text-align: end;
  }

  .client-request {
    /*padding-top: 0.5em;
    padding-left: 5em;  
    padding-right: 20em;*/
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding: 0px 10px
  }

  .space1 {
      margin-bottom: 50px;
      margin-top: 15px;
  }
 


  .note-maps {
      font-size:  0.7rem;
      width: 100%;
      text-align: right;
      color: gray;
      padding-right: 7px;
  }

  .loginForm1 {
    max-width: 320px;
  }

  .link {
      font-size: 0.7rem;
  }

  .confirmation-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100hv;
  }

  .button-box-exp {
      display: flex;
      justify-content: space-between;
  }
  
  @keyframes ui-progress-spinner-color {
    0% {
        stroke: #1a1312!important;
    }
    40% {
        stroke: #0057e7!important;
    }
    66% {
        stroke: #008744!important;
    }
    80%,
    90% {
        stroke: #ffa700!important;
    }
}

.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}

body .p-dropdown .p-dropdown-label .p-dropdown-trigger .p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item .p-dropdown-filter-container .p-dropdown-filter .p-dropdown-open .p-multiselect .p-multiselect-label-container .p-multiselect-label-container .p-multiselect-trigger .p-multiselect-filter-container .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-multiselect-token .p-chips-token-icon .p-chips-token-label {
    overflow: visible;
 }