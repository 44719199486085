:root {
  --amplify-primary-color: #5F9E9F;
  --amplify-primary-tint: #5F9E9F;
  --amplify-primary-shade: #5F9E9F;
  --footer-color: #5F9E9F;
}

.amplify-forgot-password{
  text-align:right;
}

.sign-in-form-footer {
  justify-content: flex-start!important;
}

.redQueen {
  max-width: 200px;
}

.red-box {
  padding: 50px 80px 10px 80px;
  text-align: initial;
}

.logo{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-init {
  text-align: center;
  padding-top: 100px;
  margin-bottom: -170px;
}

.button{
  background-color: red;
}

.App {
  justify-content: center;
  align-items: center;
  height: auto;
  display: flex;
}

.form {
  margin-top: 20px;
}
.form2{
  margin-top: 25px;
}

.card {
  margin: 30px 0px -15px 0px;
}

.text {
  color: #5F9E9F;
  text-align: center;
}

.text1 {
  color: #5F9E9F;
}

.loginForm {
  padding: 30px;
}

.login-button {
  width: 130px;
}

.forgot-box {
  width: 100%;
  justify-content: flex-end;
}

amplify-authenticator {
  --box-shadow: none;
}

amplify-sign-in {
  --footer-color: red;
}

.error {
  margin-top: 7px;
  color: rgb(185, 0, 0);
  position: absolute;
  font-size: 0.7rem;
  border: 1px solid rgba(255, 0, 0,0.2);;
  padding: 2px 3px;
  background: rgba(255, 0, 0,0.15);
  border-radius: 2px;
  transition: linear 0.2s;
  max-width: 280px;
}

.eye {
  cursor: pointer;
}